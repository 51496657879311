import React, { useCallback, useEffect, useState } from "react";
import recording_and_transcripts from "../assests/images/recording_and_transcripts.svg";
import "./Dashboard.css";
import { NavLink } from "react-router-dom";
import API_PATH from "../Constants/api-path";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, createSearchParams } from "react-router-dom";
import { Calendar, Timer } from "../Utils/Icons";
import moment from "moment";
import { IoIosShareAlt } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { LoadingOutlined } from "@ant-design/icons";

import {
  Dropdown,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Popover,
  Spinner,
} from "react-bootstrap";
import userColored from "../assests/images/userColored.svg";
import { convertToHtmlTranscript } from "../Utils/Util";
import jsPDF from "jspdf";
import { BsStars } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import { Button, Modal } from "antd";
import nextyn_logo from "../assests/images/Nextyn_logo.png";
import axios from "axios";

const CallRecords = () => {
  const [recordingData, setRecordingData] = useState("");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [spinner, setSpinner] = useState(false);
  const [userEmail, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useNavigate();
  const [workData, setworkData] = useState({});
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [payload, setPayload] = useState({
    email: "",
    permission: "editi",
    _id: "",
  });

  const handleChange = React.useCallback(
    (event) => {
      setPayload({ ...payload, [event.target.name]: event.target.value });
      if (event?.target?.value?.length > 0) setShow(true);
      else setShow(false);
    },
    [payload]
  );

  const handleRequestInputs = React.useCallback((e) => {
    const key = e.target.name;
    const value = e.target.value;
    setEmail(value);
  }, []);

  const headers = React.useMemo(
    () => ({
      "x-access-token": userData.access_token,
    }),
    [userData.access_token]
  );

  const getRecordings = useCallback(async () => {
    try {
      let domain = userData.user.email.split("@")[1];
      const res = await fetch(
        `${API_PATH.MEETING_LIST}/${domain}/${userData.user._id}/false?page=1&limit=5`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        setSpinner(true);
        setRecordingData(result?.data);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [headers, userData.user._id, userData.user.email]);

  useEffect(() => {
    getRecordings();
  }, [getRecordings]);

  const goToDetailsPage = React.useCallback(
    (meetingId) => {
      history({
        pathname: "/call_recordings/transcript",
        search: `?${createSearchParams({ meetingId })}`,
      });
    },
    [history]
  );

  const convertDate = React.useCallback((date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      let p = `${localDateString}`;
      return p;
    } else {
      return "---------";
    }
  }, []);

  const getTimeDifference = React.useCallback((start, end) => {
    if (start && end) {
      var timeDiff = moment(end).diff(moment(start));
      var hh = Math.floor(timeDiff / 1000 / 60 / 60);
      hh = ("0" + hh).slice(-2);

      timeDiff -= hh * 1000 * 60 * 60;
      var mm = Math.floor(timeDiff / 1000 / 60);
      mm = ("0" + mm).slice(-2);

      timeDiff -= mm * 1000 * 60;
      var ss = Math.floor(timeDiff / 1000);
      ss = ("0" + ss).slice(-2);

      var total = hh + ":" + mm + ":" + ss;
      return total;
    }
  }, []);

  const replaceTxt = React.useCallback((txt, para) => {
    txt = txt?.toLowerCase();
    for (var i = 0; i < para.length; i++) {
      txt = txt?.replace(para[i], "");
    }
    return txt;
  }, []);

  const handleViewMoreClick = React.useCallback((id) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  }, []);


  const getTranscriptData = React.useCallback(async (id) => {
    try {
      let response = await axios.get(`${API_PATH.GET_ASSEMBLY_RESPONSE}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        }
      })
      return response?.data?.data
    } catch (error) {
      console.error('error: ', error);

    }
  }, [userData.access_token])

  const downloadTranscript = React.useCallback(
    async (transcriptData) => {
      setLoading(true);
      let assemblyResponceData = await getTranscriptData(transcriptData?.meetingId)
      let data = { ...transcriptData, assembly_response: assemblyResponceData?.assembly_response }
      let content = convertToHtmlTranscript(data);
      content = content.replaceAll("</div>,", "</div>");
      content = content.replaceAll(" ,", ",");
      content = content.replaceAll(" .", ".");
      try {
        const pdf = new jsPDF({
          orientation: "p",
          unit: "pt",
          format: "a4",
          compress: true,
          width: 775,
        });

        pdf.setFontSize(12);
        pdf.html(content, {
          async callback(pdf) {
            addFooters();
            await pdf.save(
              `Nextyn-Transcript Of - ${replaceTxt(transcriptData?.topic, [
                "nextyn",
                "micro",
                "consulting",
                "-consulting",
                "call - ",
                // "call",
                "-",
              ])} - ${convertDate(transcriptData?.recording_start)}`
            );
            setLoading(false);
          },
          x: 10,
          y: 10,
          margin: [10, 10, 80, 10],
          width: 800,
          windowWidth: 1300,
          pageSplit: true,
          autoPaging: "text",
        });

        function addFooters() {
          const pageCount = pdf.internal.getNumberOfPages();
          for (var i = 1; i <= pageCount; i++) {
            pdf?.setPage(i);
            pdf?.addImage(assignImage(nextyn_logo), "png", 486, 760, 100, 0);
            pdf?.rect(13, 800, 570, 0.5, "F");

            pdf?.setFontSize(7);
            pdf?.setTextColor(196, 10, 54);
            pdf?.text(
              `Consulting call transcript generated for ${userData?.user?.name
              }(${userData?.user?.email}) of ${userData?.user?.company_id?.companyName
              } on ${convertDate(new Date())}`,
              300,
              815,
              "center"
            );

            pdf?.setFontSize(7);
            pdf?.setTextColor(196, 10, 54);
            pdf?.text(
              `This transcript is for internal use by ${userData?.user?.company_id?.companyName} only. It contains confidential information and should not be publicly released or circulated.`,
              300,
              825,
              "center"
            );
          }
        }
      } catch (error) {
        console.log("error: ", error);
      }
    },
    [convertDate, getTranscriptData, replaceTxt, userData?.user?.company_id?.companyName, userData?.user?.email, userData?.user?.name]
  );

  const assignImage = (image) => {
    let img = new Image();
    img.src = image;
    return img;
  };

  const downloadAudio = React.useCallback((audioUrl) => {
    const link = document.createElement("a");
    link.href = audioUrl;
    link.download = "downloaded_audio.mp3";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);
  const getFolderTable = React.useCallback(() => {
    if (recordingData instanceof Array) {
      return recordingData.map(function (object, i) {
        const {
          _id,
          transcript,
          recording_start,
          topic,
          permittedUsers,
          recording_end,
          expert_company,
          expert_designation,
          chapters,
          s3_download_url,
        } = object;
        return (
          <div key={i}>
            <div className="transcriptNew my-2 p-2">
              <div className="cursor-pointer">
                <div className="d-md-flex justify-content-between align-items-center">
                  <div>
                    <p
                      style={{
                        color: "#5746ec",
                        fontSize: "14px",
                      }}
                      className="fw-bolder m-0 p-0"
                    >
                      {replaceTxt(topic, [
                        "nextyn",
                        "micro",
                        "consulting",
                        "-consulting",
                        "call - ",
                        // "call",
                        "-",
                      ])?.toUpperCase()}
                    </p>
                    {(expert_company || expert_designation) && <span className="text-muted fw-normal">
                      {/* {expert_company}{expert_designation ? `, ${expert_designation}` : null} */}
                      {expert_company?.split(",")?.map((comp, index) => {
                        return <>
                          <span>{expert_designation?.split(",")?.[index]}, {comp}
                            {expert_company?.split(",")?.length - 1 !== index && <span> | </span>}
                          </span>
                        </>
                      })}
                    </span>}
                  </div>

                  <div
                    className="d-flex align-items-center"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    <span className="d-flex align-items-center me-4">
                      <Calendar />
                      <span className="ms-1">
                        {convertDate(recording_start)}
                      </span>
                    </span>
                    <span className="d-flex align-items-center">
                      <Timer />
                      <span className="ms-1">
                        {getTimeDifference(recording_start, recording_end)}
                      </span>
                    </span>
                  </div>
                </div>

                <div className="my-3 tras_smmry">
                  <div className="d-flex flex-direction-row justify-content-between mb-2">
                    <span
                      className="fw-bolder"
                      aria-hidden="true"
                      style={{ fontSize: "16px" }}
                    >
                      <BsStars style={{ fontSize: "14px" }} /> AI Summary
                    </span>
                  </div>

                  <ul className="listType mb-0 ps-3">
                    {chapters?.map((question, index) => (
                      <li
                        style={{ listStyleType: "disc" }}
                        className="mb-1"
                        key={index}
                      >
                        {question?.headline}
                      </li>
                    ))}
                    {expanded[_id] &&
                      chapters?.map((question, index) => (
                        <li
                          style={{ listStyleType: "disc" }}
                          className="mb-1"
                          key={index}
                        >
                          {question?.headline}
                        </li>
                      ))}
                  </ul>
                  {chapters?.length > 3 && (
                    <Button
                      className="text-start p-0"
                      type="link"
                      block
                      onClick={() => handleViewMoreClick(_id)}
                      style={{ fontSize: "12px", color: "#5746ec" }}
                    >
                      {expanded[_id] ? "View less" : "View more..."}
                    </Button>
                  )}
                </div>

                <div
                  className="d-flex align-items-center"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <span className="d-flex align-items-center">
                    <button
                      className="btn viewButton"
                      style={{ fontSize: "12px" }}
                      onClick={() => {
                        goToDetailsPage(_id);
                      }}
                    >
                      View
                    </button>
                  </span>

                  <span
                    className="d-flex align-items-center ms-2 ms-md-3"
                    style={{ color: "#5746ec", cursor: "pointer" }}
                    onClick={() => downloadAudio(s3_download_url)}
                  >
                    <FiDownload />
                    <span className="ms-1">Audio</span>
                  </span>
                  <span
                    className="d-flex align-items-center ms-2 ms-md-3"
                    style={{ color: "#5746ec", cursor: "pointer" }}
                    onClick={() => {
                      setIndex(i);
                      downloadTranscript(object);
                    }}
                  >
                    {loading && index === i ? (
                      <LoadingOutlined />
                    ) : (
                      <FiDownload />
                    )}
                    <span className="ms-1">Transcript</span>
                  </span>
                  {/* <span
                    className="d-flex align-items-center ms-3"
                    style={{ color: "#5746ec", cursor: "pointer" }}
                    data-bs-toggle="modal"
                    data-bs-target="#shareModal2"
                    onClick={() => setworkData(object)}
                  >
                    <IoIosShareAlt />
                    <span className="ms-1">Share</span>
                  </span> */}
                  <span
                    className="d-flex align-items-center ms-2 ms-md-3"
                    style={{ color: "#5746ec", cursor: "pointer" }}
                    onClick={() => {
                      setOpen(true)
                      setworkData(object)
                    }}
                  >
                    <MdDelete />
                    <span className="ms-1">Delete</span>
                  </span>
                </div>
              </div>
            </div>
            <NavLink
              to={`/collaborate/transcript?meetingId=${_id}`}
              style={{ display: "none" }}
            >
              <div className="col folderSection mb-4">
                <div className="comon-notes">
                  <div className="d-flex justify-content-between align-items-center">
                    <figure className="m-0">
                      <img src="images/folder-icon.png" alt="folder" />
                    </figure>
                    <div className="auto-sec ms-2">
                      <p
                        className="m-0"
                        style={{ fontSize: "12px", fontWeight: 500 }}
                      ></p>
                    </div>
                  </div>
                  <h6 className="note-titel mt-4">{topic}</h6>
                  <p className="paragraph">
                    {" "}
                    <p className="text-wrap-afte-three-line">{transcript} </p>
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="mt-3 mb-0">
                      {convertDate(recording_start)}
                    </h6>
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      //   key={i}
                      placement={"top"}
                      overlay={
                        <Popover id={`popover-positioned`}>
                          <Popover.Body className="p-2">
                            <div>
                              <small>
                                {" "}
                                Shared with {permittedUsers?.length} people
                              </small>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <img
                        style={{ position: "relative", top: "11px" }}
                        src={userColored}
                        alt="img"
                      />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
        );
      });
    }
  }, [
    convertDate,
    downloadAudio,
    downloadTranscript,
    expanded,
    getTimeDifference,
    goToDetailsPage,
    handleViewMoreClick,
    index,
    loading,
    recordingData,
    replaceTxt,
  ]);

  const deleteTranscript = React.useCallback(
    async (e) => {
      e.preventDefault();
      // console.log("workData", workData);
      try {
        const res = await fetch(`${API_PATH.MEETING_DELETE}/${workData._id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
        });

        const result = await res.json();
        if (result) {
          document.getElementById("DeleteModal1").click();
          // setPayload(payload.id)
          // window.history.go(-1);
          setOpen(false)
        } else {
          setOpen(false)
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        setOpen(false)

        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    },
    [userData.access_token, workData._id]
  );

  const updatePermission = React.useCallback(
    async (permission, email) => {
      try {
        const res = await fetch(
          `${API_PATH.MEETING_ID_UPDATE}/${workData._id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify({ permission: permission, email: email }),
          }
        );

        const result = await res.json();
        if (result) {
          document.getElementById("DeleteModal1").click();
          // window.history.go(-1);
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    },
    [userData.access_token, workData._id]
  );

  const getDefaultText = React.useCallback((permission) => {
    if (permission === "editi") return "Can invite";
    if (permission === "edit") return "Can edit";
    if (permission === "view") return "Can view";
  }, []);

  const getPUserList = React.useCallback(() => {
    // console.log(workData);
    if (workData.permittedUsers instanceof Array) {
      return workData.permittedUsers.map(function (object, i) {
        const { _id, firstNmae, email, permission, username } = object;
        return (
          <>
            <div className="shareWrppr" id={_id}>
              <div className="emailSection">
                <div className="profileIconWrppr">
                  <div className="avatarImg">
                    <img
                      width="45px"
                      src={
                        "https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=" +
                        email
                      }
                      alt={email}
                    />
                  </div>
                  <div className="ms-3">
                    <div id="qa-USER_LIST_USER_NAME" className="nameInfo">
                      <span className="">
                        {firstNmae ? firstNmae : username}
                      </span>
                    </div>
                    <div className="emailInfo" id="qa-USER_LIST_EMAIL">
                      <span className="">{email}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Yourr_9w71MjtL3S9I4G">
                <div className="">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dropdownBtn"
                      id="dropdown-basic"
                      style={{
                        background: "none",
                        border: "none",
                        color: "#000000",
                      }}
                    >
                      {getDefaultText(permission)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#/action-1"
                        onClick={() => updatePermission("editi", email)}
                      >
                        Can invite
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-3"
                        onClick={() => updatePermission("view", email)}
                      >
                        Can view
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        );
      });
    }
  }, [getDefaultText, updatePermission, workData.permittedUsers]);

  const stopSharing = async (e) => {
    e.preventDefault();

    try {
      let payload = {};
      payload.permittedUsers = [];
      const res = await fetch(`${API_PATH.MEETING_ID_UPDATE}/${workData._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify(payload),
      });

      const result = await res.json();
      // console.log("oufdygdcfrf", result);
      if (result) {
        // document.getElementById("DeleteModal1").click();
        window.location.reload();
        return toast.success("User removed successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      // console.log("errrrrrr", error);
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const makeUpdate = async () => {
    // console.log(payload);

    try {
      const res = await fetch(`${API_PATH.MEETING_ID_UPDATE}/${workData._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify({
          permission: payload.permission,
          email: payload.email,
        }),
      });

      const result = await res.json();
      if (result) {
        setEmail("");
        // getNotes();
        getRecordings();
        return toast.success("User added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <div className="body-expart-div">
        <div className="mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0" style={{ fontSize: "20px" }}>
              <strong className="fw-normal" style={{ color: "#5746ec" }}>
                Call Recordings & Transcript
              </strong>
            </h4>
            <NavLink to={"/call_recordings"}>
              <button className="btn viewButton">View</button>
            </NavLink>
          </div>

          <div>
            {spinner ? (
              <>
                {recordingData.length !== 0 ? (
                  <div className="notes-d-section mt-4">{getFolderTable()}</div>
                ) : (
                  <div className="row justify-content-center align-items-center mt-5">
                    <div className="col-12 col-md-4">
                      <img
                        src={recording_and_transcripts}
                        alt="recording_and_transcript"
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <p style={{ fontSize: "18px", fontWeight: "300" }}>
                        Recordings & Transcripts of your consulting calls will
                        appear here.
                      </p>
                      <p style={{ fontSize: "18px", fontWeight: "300" }}>
                        Seems like you haven't completed any consulting calls
                        yet.
                      </p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: "20px" }}
                >
                  <div role="status">
                    <Spinner animation="border" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className="modal fade addmodal cm-modal-all"
          id="shareModal2"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ border: "none", marginTop: "10px" }}
              >
                <h5 className="modal-title" id="exampleModalLabel">
                  Share call recording
                </h5>
                <button
                  id="closeContentModal"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div>
                  {/* <div className="modalWrapper">
                  <div className="linkWrapper">
                    <div className="linkChild">
                      <span className="linkTitle">Shareable link</span>
                    </div>
                    <div className="copyLink">
                      <CopyToClipboard copyText="https://www.evernote.com/shard/s620/sh/53364b71-095d-5d96-eae4-024eb45186cc/190416813732a37ee359c6cf6d40ba90" />
                    </div>
                  </div>
                  <div role="link" tabIndex="0">
                    <input
                      className="shareableLink"
                      type="text"
                      readOnly
                      value="https://www.evernote.com/shard/s620/sh/53364b71-095d-5d96-eae4-024eb45186cc/190416813732a37ee359c6cf6d40ba90"
                    />
                  </div>
                  <div style={{ marginTop: "5px", fontSize: "14px" }}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="me-2"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.07 11.373a3.483 3.483 0 11.002-6.967 3.483 3.483 0 01-.001 6.967zm0-8.707c-3.243 0-5.962 2.221-6.736 5.224.774 3.003 3.493 5.224 6.737 5.224s5.962-2.221 6.736-5.224c-.774-3.003-3.492-5.224-6.736-5.224zM9.813 7.89a1.741 1.741 0 11-3.482 0 1.741 1.741 0 013.482 0z"
                        fill="#00A82D"
                      ></path>
                    </svg>
                    Anyone with the link can <strong>view</strong>
                  </div>
                </div> */}

                  <div className="mt-4 inviteSection text-left">
                    <div className="inviteTitle text-start">Invite someone</div>
                    <InputGroup className="mb-3 inputGroupWrppr">
                      <FormControl
                        placeholder="Enter email"
                        aria-label="Enter email"
                        aria-describedby="basic-addon2"
                        value={userEmail}
                        name="email"
                        className="inputGroupInput"
                        onChange={(event) => {
                          handleRequestInputs(event);
                          handleChange(event);
                        }}
                      />
                      <InputGroup.Text
                        id="basic-addon2"
                        className="inputGroups"
                        style={{ backgroud: "#fff" }}
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            className="dropdownBtn"
                            id="dropdown-basic"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "#000",
                            }}
                          >
                            {getDefaultText(payload?.permission)}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              href="#/action-1"
                              onClick={() =>
                                updatePermission("editi", payload?.email)
                              }
                            >
                              Can invite
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-3"
                              onClick={() =>
                                updatePermission("view", payload?.email)
                              }
                            >
                              Can view
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>

                  {show ? (
                    <div
                      className="d-flex justify-content-end"
                      style={{ border: "none" }}
                    >
                      <button
                        type="button"
                        className="btn btn-secondary"
                        style={{ marginRight: "1rem" }}
                      // onClick={() => {
                      //   setEmail("");
                      // }}
                      >
                        Cancel
                      </button>
                      {/* <button
                      type="button"
                      className="btn btn-primary"
                      style={{ backgroundColor: "#5746ec", border: "none" }}
                      onClick={() =>
                        updatePermission(payload.permission, payload.email)
                      }
                    >
                      Submit
                    </button> */}

                      {workData?.permittedUsers?.filter(
                        (o) => o.email === userEmail?.trim()
                      ).length > 0 ? (
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ backgroundColor: "#5746ec", border: "none" }}
                          disabled
                        // onClick={() =>makeUpdate()}
                        >
                          Submit
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ backgroundColor: "#5746ec", border: "none" }}
                          // onClick={() =>updatePermission(
                          //   payload.permission,
                          //   payload.email
                          // )}
                          onClick={() => makeUpdate()}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  <div>
                    <div className="sharedInfoWrapper">
                      <div className="sharedInfoChild">
                        <div className="sharedInfoProfile">
                          {getPUserList()}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ZP9BDnxHhGA9rSLoFsa3">
                    <button
                      id="qa-SHARE_DIALOG_STOP"
                      className="cDCnsp1vcViX1pVJM7MV"
                      type="button"
                      tabIndex="0"
                      onClick={stopSharing}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Stop sharing
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delete modal */}
        <Modal title={<h4 className="modal-title">Are you sure ?</h4>}
          open={open}
          centered
          width={600}
          onCancel={() => {
            setOpen(false)
          }}
          footer={<div
            className="d-flex justify-content-end gap-2"
          >
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setOpen(false)
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={deleteTranscript}
              className="btn btn-primary"
              style={{ backgroundColor: "#5746ec", border: "none" }}
            >
              Delete
            </button>
          </div>}
        >
          <span>
            Do you really want to deactivate these records? This
            process cannot be undone.
          </span>
        </Modal>
      </div>
    </>
  );
};
export default CallRecords;
