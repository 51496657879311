import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination1 from "react-js-pagination";
import { toast } from "react-toastify";
import "../App.css";
import API_PATH from "../Constants/api-path";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  Undo,
  Link,
  Heading,
  List,
  Alignment,
  Indent,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";

import { Checkbox, Select, Form, Input, Modal, Button } from "antd";
import countryList from "./CountryList";
import { setSelectedProject } from "../Redux/features/project.slice";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, } from "react-bootstrap";
import { Country } from "country-state-city";
import { LoadingOutlined } from "@ant-design/icons";

export const UpdateProjectModal = ({
  open,
  setOpen,
  getProjectList = () => { },
  getholdProjectList = () => { },
  getcompleteProjectList = () => { },
}) => {
  const loginDetails = JSON.parse(localStorage.getItem("userData"));
  const [form] = Form.useForm()
  const [startDate, setStartDate] = useState("");
  const [value, setValue] = useState("");
  const [inputList, setInputList] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [message, setMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [allDatas, setAlldatas] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [, setCall_recorded] = useState(0);
  const [loading1, setLoading1] = useState(false);
  const [userList, setUserList] = useState([]);
  const countries = Country?.getAllCountries();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const { selectedProject } = useSelector((state) => state.projectReducer);
  const headers = React.useMemo(
    () => ({
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.token}`,
    }),
    [userData?.token]
  );
  const dispatch = useDispatch();

  const [projectDetails, setProjectDetails] = useState({
    project_title: "",
    expected_calls: "",
  });
  const handleClose = React.useCallback(() => {
    setStartDate("");
    setValue("");
    setOpen(false);
    setEmailList([]);
    dispatch(setSelectedProject({}));
    setInputList([]);
    setSelectList([]);
    setProjectDetails({
      project_title: "",
      expected_calls: "",
    });
    form.resetFields()
  }, [dispatch, form, setOpen]);

  const handelChange = React.useCallback(
    (e) => {
      let key = e.target.name;
      let value = e.target.value;
      setProjectDetails({ ...projectDetails, [key]: value });
    },
    [projectDetails]
  );

  const handleEditorChange = React.useCallback((content) => {
    setValue(content);
  }, []);

  const handlePageChange = React.useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  useEffect(() => {
    if (Object?.keys(selectedProject)?.length > 0) {
      setProjectDetails({
        expected_calls: selectedProject?.expected_calls,
        project_title: selectedProject?.project_title,
      });
      setStartDate(new Date(selectedProject?.project_deadline));
      setSelectList(
        selectedProject?.country?.split(",")?.map((country) => {
          return country
        })
      );
      setInputList(
        selectedProject?.target_companies?.split(",")?.map((comp) => {
          return comp
        })
      );
      setEmailList(
        selectedProject?.invite_team?.split(",")?.map((team, index) => {
          let teamEmail = team?.split("@")?.length <= 1 ? `${team}@${userData?.user?.companyDomain}` : team
          return teamEmail
        })
      );
      setValue(selectedProject?.question);
      form.setFieldsValue({
        "project_title": selectedProject?.project_title,
        "expected_calls": selectedProject?.expected_calls,
        "project_deadline": new Date(selectedProject?.project_deadline),
        "geography_of_experts": selectedProject?.country?.split(",")?.map((country) => {
          return country
        }),
        "target_companies": selectedProject?.target_companies?.split(",")?.map((comp) => {
          return comp
        }),
        "team_member": selectedProject?.invite_team?.split(",")?.map((team, index) => {
          let teamEmail = team?.split("@")?.length <= 1 ? `${team}@${userData?.user?.companyDomain}` : team
          return teamEmail
        }),
        "overview": selectedProject?.question

      })
    }
  }, [form, selectedProject, userData?.user?.companyDomain]);

  //COMMENTED COZ NOT FINDING THIS API ON SERVER ERROR
  // useEffect(() => {
  //   getProjects();
  // }, [currentPage, getProjects]);
  console.log("get form fields", form.getFieldsValue());
  const handelChecked = React.useCallback((e) => {
    if (e.target.checked) {
      setCall_recorded((current) => current + 1);
      // console.log("✅ Checkbox is checked");
    } else {
      setCall_recorded((current) => current - 1);
      // console.log("⛔️ Checkbox is NOT checked");
    }
    setIsSubscribed((current) => !current);
  }, []);

  const handleInputChangeCompany = React.useCallback(
    (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    },
    [inputList]
  );

  const handleInputChangeCountry = React.useCallback(
    (e, index) => {
      const { id, value } = e.target;
      const list = [...selectList];
      list[index][id] = value;
      setSelectList(list);
    },
    [selectList]
  );

  const checkEmail = React.useCallback((email) => {
    const regEx = /[@]/;
    if (!regEx.test(email)) {
      setMessage("");
    } else {
      return setMessage("Email is invalid");
    }
  }, []);


  const handleRemoveClick = React.useCallback(
    (data, index) => {
      if (data === "input") {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
      } else if (data === "select") {
        const list = [...selectList];
        list.splice(index, 1);
        setSelectList(list);
      }
    },
    [inputList, selectList]
  );

  // handle click event of the Add button
  const handleAddClick = React.useCallback(
    (data) => {
      if (data === "input") {
        setInputList([...inputList, { company: "" }]);
      } else if (data === "select") {
        setSelectList([...selectList, { country: "" }]);
      }
    },
    [inputList, selectList]
  );

  const handleSubmit = React.useCallback(
    async () => {
      setSpinner(true);
      let countryData = [];

      let companyData = [];
      let emailData = [];
      // if (loginDetails?.user?.email) {
      //   emailData?.push(loginDetails?.user?.email?.split("@")[0]);
      // }

      // for (var Country in selectList) {
      //   countryData?.push(selectList[Country]?.country);
      // }

      // for (var Company in inputList) {
      //   companyData?.push(inputList[Company]?.company);
      // }

      // for (var index in emailList) {
      //   if (
      //     emailList?.[index]?.value !== emailData[0] &&
      //     emailList?.[index]?.value !== ""
      //   ) {
      //     if (!emailData?.includes(emailList?.[index]?.value?.split("@")?.[0])) {
      //       emailData?.push(emailList?.[index]?.value?.split("@")?.[0]);
      //     }
      //   }
      // }
      let payload = {
        project_title: projectDetails?.project_title,
        user_id: loginDetails?.user?._id,
        invite_team: loginDetails?.user?.email && loginDetails?.user?.email?.split("@")?.[0] ?
          [...new Set([...emailList?.map((list) => list?.split("@")?.[0]), loginDetails?.user?.email?.split("@")?.[0]])]?.join(",") : emailList?.map((list) => list?.split("@")?.[0])?.join(","),
        // invite_team: loginDetails?.user?.email && loginDetails?.user?.email?.split("@")?.[0] ? [...emailList?.map((list) => list?.split("@")?.[0]), loginDetails?.user?.email?.split("@")?.[0]]?.join(",") : emailList?.map((list) => list?.split("@")?.[0])?.join(","),
        project_id: selectedProject?._id,
        // country: countryData[0],
        country: selectList?.join(","),
        target_companies: inputList?.join(","),
        question: value,
        project_deadline: startDate?.toISOString(), // Convert to ISO string
        expected_calls: projectDetails?.expected_calls,
      };
      // if (
      //   payload.project_title === "" ||
      //   payload.expected_calls === "" ||
      //   payload.question === "" ||
      //   payload.project_deadline === ""
      // ) {
      //   return toast.warning("All fields are mandatory!", {
      //     position: toast.POSITION.TOP_RIGHT,
      //     autoClose: 3000,
      //   });
      // } else {
      //   <></>;
      // }

      try {
        await fetch(`${API_PATH?.UPDATE_PROJECT}`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(payload),
        });
        setSpinner(false);
        getProjectList();
        getholdProjectList();
        getcompleteProjectList();
        handleClose();
        return toast.success("Project updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } catch (error) {
        setOpen(false);
        return toast.error("Something went wrong. Server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    },
    [
      emailList,
      getProjectList,
      getcompleteProjectList,
      getholdProjectList,
      handleClose,
      headers,
      inputList,
      loginDetails?.user?._id,
      loginDetails?.user?.email,
      projectDetails?.expected_calls,
      projectDetails?.project_title,
      selectList,
      selectedProject?._id,
      setOpen,
      startDate,
      value,
    ]
  );

  const getUsers = React.useCallback(async () => {
    axios
      .get(`${API_PATH.USER_MANAGEMENT}/${userData?.user?.company_id._id}`, {
        method: "GET",
        headers: headers,
      })
      .then((res) => {
        console.log(res, "ress");
        setUserList([...res.data.data]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [headers, userData?.user?.company_id._id]);
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <Modal
        title={<> <h4 className="mb-0">Update Project</h4>
          <p
            className="mb-0"
            style={{ color: "#526fd7", fontSize: "12px !important" }}
          >
            Detail out your requirement to launch a custom recruitment request{" "}
          </p></>}
        centered
        size={"lg"}
        open={open}
        width={800}
        style={{
          height: 550,
          overflowY: "scroll"
        }}
        className="bt_modal"
        scrollable={true}
        onCancel={() => {
          setOpen(false);
          handleClose();
        }}
        footer={false}
      >
        <Form form={form}
          onFinish={() => {
            console.log("finish?");
            handleSubmit()
          }} name="update_project_form">
          <Row className="">
            <Col md={12} className="">
              <Form.Item labelAlign="left" className="labelTop" name="project_title" label="Project Title" rules={[
                {
                  required: true,
                  message: "Project Title is required",
                },
              ]}>
                <Input
                  type="text"
                  size="large"
                  placeholder="Project Title"
                  name="project_title"
                  value={projectDetails.project_title}
                  onChange={handelChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="">
            <Col md={6} className="">
              <Form.Item labelAlign="left" className="labelTop" name="expected_calls" label="Expected Calls" rules={[
                {
                  required: true,
                  message: "Expected Calls is required",
                },
              ]}>
                <Select
                  name="expected_calls"
                  className="w-100"
                  placeholder="Expected Calls"
                  value={projectDetails.expected_calls || null}
                  onChange={(value) => {
                    setProjectDetails({ ...projectDetails, expected_calls: value });
                  }}
                  size="large"
                >
                  <Select.Option value="1">1</Select.Option>
                  <Select.Option value="2">2</Select.Option>
                  <Select.Option value="3">3</Select.Option>
                  <Select.Option value="4">4</Select.Option>
                  <Select.Option value="5">5</Select.Option>
                  <Select.Option value="6">6</Select.Option>
                  <Select.Option value="7">7</Select.Option>
                  <Select.Option value="8">8</Select.Option>
                  <Select.Option value="9">9</Select.Option>
                  <Select.Option value="10">10</Select.Option>
                </Select>
              </Form.Item>

            </Col>

            <Col md={6} className="">
              <Form.Item labelAlign="left" className="labelTop" name="project_deadline" label="Project Deadline" rules={[
                {
                  required: true,
                  message: "Project Deadline is required",
                },
              ]}>
                <DatePicker
                  style={{ cursor: "default" }}
                  inputProps={{ readOnly: true }}
                  className="form-select"
                  minDate={new Date()}
                  value={startDate}
                  selected={startDate}
                  placeholderText="MM/DD/YYYY"
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  dateFormat="dd/MM/yyyy"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="">
            <Col md={6} className="">
              <Form.Item labelAlign="left" className="labelTop" name="geography_of_experts" label="Geography Of Experts" rules={[
                {
                  required: true,
                  message: "Geography of experts is required",
                },
              ]}>

                <Select
                  mode="multiple"
                  name="geography_of_experts"
                  showSearch
                  filterOption={(input, option) => {
                    return option?.value?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }

                  }
                  className="w-100"
                  options={countryList}
                  value={selectList}
                  placeholder="Select Country"
                  onChange={(value) => {
                    setSelectList(value)
                  }}
                >   {countries?.map((country, index) => (
                  <Select.Option
                    key={index + 5}
                    value={country?.name}
                    label={country?.name}
                  >
                    {country?.name}
                  </Select.Option>
                ))}</Select>
              </Form.Item>

            </Col>
            <Col md={6} className="col-md-6">
              <Form.Item labelAlign="left" className="labelTop" name="target_companies" label="Target Companies" rules={[
                {
                  required: true,
                  message: "Target Companies are required",
                },
              ]}>
                <Select
                  mode="tags"
                  className="w-100"
                  open={false}
                  value={inputList}
                  placeholder="Target Companies"
                  onChange={(value) => {
                    setInputList(value)
                  }}
                />
              </Form.Item>

            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Item labelAlign="left" className="labelTop" name="team_member" label="Invite Your Team" >

                <Select
                  mode="multiple"
                  className="w-100"
                  name="Select Team Member"
                  showSearch
                  filterOption={(input, option) =>
                    option?.props?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  } placeholder="Select Team Member"
                  value={emailList}
                  options={userList?.filter((list) => list?.email !== userData?.user?.email)?.map((item) => ({
                    label: item?.email,
                    value: item?.email,
                    key: item?._id,
                  }))}
                  onChange={(value) => {
                    setEmailList(value);
                  }}
                />
              </Form.Item>

            </Col>
          </Row>
          <Row>
            <Col md={12} className="col-lg-12">
              <div className="form-group">
                <div className="position-relative ckeditor_client">
                  <Form.Item labelAlign="left" className="labelTop" name="overview" label="Project Overview" rules={[
                    {
                      required: true,
                      message: "Project Overview is required",
                    },
                  ]}>
                    <CKEditor
                      editor={ClassicEditor}
                      onReady={(editor) => {
                        // editorRef.current = editor;
                        console.info("Editor is ready to use!", editor);
                      }}
                      data={value}
                      config={{
                        toolbar: {
                          items: [
                            "undo",
                            "redo",
                            "|",
                            "heading",
                            "|",
                            "|",
                            "bold",
                            "italic",
                            "|",
                            "alignment:left",
                            "alignment:center",
                            "alignment:right",
                            "alignment:justify",
                            "|",
                            "bulletedList",
                            "numberedList",
                          ],
                        },
                        plugins: [
                          Bold,
                          Essentials,
                          Italic,
                          Paragraph,
                          Undo,
                          Link,
                          Heading,
                          List,
                          Alignment,
                          // BulletedList,
                          // NumberedList,
                          Indent,
                        ],
                        heading: {
                          options: [
                            {
                              model: "paragraph",
                              title: "Paragraph",
                              class: "ck-heading_paragraph",
                            },
                            {
                              model: "heading1",
                              view: "h1",
                              title: "Heading 1",
                              class: "ck-heading_heading1",
                            },
                            {
                              model: "heading2",
                              view: "h2",
                              title: "Heading 2",
                              class: "ck-heading_heading2",
                            },
                            {
                              model: "heading3",
                              view: "h3",
                              title: "Heading 3",
                              class: "ck-heading_heading3",
                            },
                            {
                              model: "heading4",
                              view: "h4",
                              title: "Heading 4",
                              class: "ck-heading_heading4",
                            },
                            {
                              model: "heading5",
                              view: "h5",
                              title: "Heading 5",
                              class: "ck-heading_heading5",
                            },
                            {
                              model: "heading6",
                              view: "h6",
                              title: "Heading 6",
                              class: "ck-heading_heading6",
                            },
                          ],
                        },
                        alignment: {
                          options: ["left", "center", "right", "justify"],
                        },
                      }}
                      onChange={(event, editor) => {
                        const newData = editor?.getData();
                        handleEditorChange(newData);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
          {/* <div className="radio2">
            <Checkbox
              className="me-2"
              onChange={handelChecked}
              value={isSubscribed}
              name="call_recorded"
              style={{ color: "#526fd7" }}
              required
            ></Checkbox>

            <label style={{ color: "#526fd7" }}>
              Request calls to be recorded
            </label>
          </div> */}
          <div className="d-flex justify-content-end gap-2">
            <Form.Item labelAlign="left" className="labelTop">

              <Button
                className="btn btntransparent"
                htmlType="button"
                // type="primary"
                onClick={() => {
                  handleClose()
                }}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item labelAlign="left" className="labelTop">
              <Button
                className="btn btnsm"
                htmlType="submit"
                type="primary"
                disabled={spinner}
              // onClick={handleSubmit}
              >
                Submit {spinner && <LoadingOutlined className="mb-1" />}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      {loading1 ? (
        <>
          {allDatas > 10 ? (
            <div className="pagination-background">
              <Pagination1
                activePage={currentPage}
                itemsCountPerPage={10}
                totalItemsCount={allDatas}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
                itemclassName="page-item"
                linkclassName="page-link"
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
