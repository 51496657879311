import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputGroup,
  OverlayTrigger,
  Popover,
  Dropdown
} from "react-bootstrap";

import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import userColored from "../assests/images/userColored.svg";
import API_PATH from "../Constants/api-path";
import { Calendar, File, MoreHorizontal, Timer } from "../Utils/Icons";
import { MoreOutlined } from '@ant-design/icons';
import {
  MdDelete,
  MdModeEditOutline,
  MdOutlineCheck,
  MdOutlineClose,
} from "react-icons/md";

function Transcript({ recordingList }) {
  console.log(recordingList._id, "recordingList-->");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [show, setShow] = useState(false);
  const [userEmail, setEmail] = useState("");
  const [workData, setworkData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const history = useNavigate();
  const [recordDetailsData, setRecordDetailsData] = useState("");
  const [payload, setPayload] = useState({
    email: "",
    permission: "editi",
    _id: "",
  });

  const handleChange = (event) => {
    if (event.target.value.length > 0) setShow(true);
    else setShow(false);
  };
  const convertDate = (date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      let p = `${localDateString} - ${localTimeString}`;
      return p;
    } else {
      return "---------";
    }
  };

  const getDefaultText = (permission) => {
    if (permission === "editi") return "Can invite";
    if (permission === "edit") return "Can edit";
    if (permission === "view") return "Can view";
  };


  useEffect(() => {
    // setSpinner(false);
    // getRecorings();
  }, []);

  const getPUserList = () => {
    console.log(workData);
    if (workData.permittedUsers instanceof Array) {
      return workData.permittedUsers.map(function (object, i) {
        const { _id, firstNmae, email, permission, username } = object;
        return (
          <>
            <div className="shareWrppr" id={_id}>
              <div className="emailSection">
                <div className="profileIconWrppr">
                  <div className="avatarImg">
                    <img
                      width="45px"
                      src={
                        "https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=" +
                        email
                      }
                      alt={email}
                    />
                  </div>
                  <div className="ms-3">
                    <div id="qa-USER_LIST_USER_NAME" className="nameInfo">
                      <span className="">
                        {firstNmae ? firstNmae : username}
                      </span>
                    </div>
                    <div className="emailInfo" id="qa-USER_LIST_EMAIL">
                      <span className="">{email}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Yourr_9w71MjtL3S9I4G">
                <div className="">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dropdownBtn"
                      id="dropdown-basic"
                      style={{
                        background: "none",
                        border: "none",
                        color: "#000000",
                      }}
                    >
                      {getDefaultText(permission)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#/action-1"
                        onClick={() => updatePermission("editi", email)}
                      >
                        Can invite
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-3"
                        onClick={() => updatePermission("view", email)}
                      >
                        Can view
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        );
      });
    }
  };



  function RemoveHtml(myString) {
    if (myString) {
      return myString.replace(/(<([^>]+)>)/gi, "");
    } else {
      return "Empty message";
    }
  }

  const headers = {
    "x-access-token": userData.access_token,
  };

  const getName = (url) => {
    return url.substring(url.lastIndexOf("/") + 1, url.length);
  };

  function getTimeDifference(start, end) {
    if (start && end) {
      var timeDiff = moment(end).diff(moment(start));
      var hh = Math.floor(timeDiff / 1000 / 60 / 60);
      hh = ("0" + hh).slice(-2);

      timeDiff -= hh * 1000 * 60 * 60;
      var mm = Math.floor(timeDiff / 1000 / 60);
      mm = ("0" + mm).slice(-2);

      timeDiff -= mm * 1000 * 60;
      var ss = Math.floor(timeDiff / 1000);
      ss = ("0" + ss).slice(-2);

      var total = hh + ":" + mm + ":" + ss;
      return total;
    }
  }

  const stopSharing = async (e) => {
    e.preventDefault();

    try {
      let payload = {};
      payload.permittedUsers = [];
      const res = await fetch(
        `${API_PATH.MEETING_ID_UPDATE}/${workData._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await res.json();
      console.log("oufdygdcfrf", result);
      if (result) {
        // document.getElementById("DeleteModal1").click();

        window.location.reload();
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }


  const goToDetailsPage = (_id) => {
    history({
      pathname: "/call_recordings/transcript",
      search: `?meetingId=${_id}`,
    });
  };


  const handleRequestInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setEmail(value);
  };


  const deleteTranscript = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `${API_PATH.MEETING_DELETE}/${workData._id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
        }
      );

      const result = await res.json();
      if (result) {
        document.getElementById("DeleteModal1").click();
        // setPayload(payload.id)
        // window.history.go(-1);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const updatePermission = async (id) => {
    console.log(id, "id");
    try {
      const res = await fetch(`${API_PATH.MEETING_ID_UPDATE}/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
      });

      const result = await res.json();
      if (result) {
        document.getElementById("DeleteModal1").click();
        // window.history.go(-1);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const makeUpdate = async () => {
    // console.log(payload);


    try {
      const res = await fetch(
        `${API_PATH.MEETING_ID_UPDATE}/${workData._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify({
            permission: payload.permission,
            email: payload.email,
          }),
        }
      );

      const result = await res.json();
      if (result) {
        setEmail("");
        // getNotes();
        return toast.success("User added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }


  const popovers = (_id) => {
    {
      console.log(_id, "_id");
    }
    <Dropdown>
      <Dropdown.Toggle className="moreOptions" id="dropdown-basic">
        <MoreHorizontal className="moreIcon" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          href="#"
          className="actionNav me-4"
          data-bs-toggle="modal"
          data-bs-target="#shareModal2"
        >
          Share
        </Dropdown.Item>
        <Dropdown.Item
          // href="#/action-2"
          onClick={() => goToDetailsPage(_id)}
        >
          View
        </Dropdown.Item>
        <Dropdown.Item
          href="#"
          className="actionNav me-4"
          data-bs-toggle="modal"
          data-bs-target="#DeleteModal1"
        >
          Remove
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>;
  };

  const getFolderTable = () => {
    if (recordingList instanceof Array) {
      return recordingList.map(function (object, i) {
        const {
          _id,
          transcript,
          recording_start,
          topic,
          permittedUsers,
          recording_end,
        } = object;
        return (
          <>
            <div className="d-flex align-items-center justify-content-between transcriptNew my-2 p-4">
              <NavLink to={`/call_recordings/transcript?meetingId=${_id}`}>
                <div className="d-flex align-items-center">
                  <File className="fileIcons" />
                  <div className="pContent ms-4">
                    <p
                      style={{
                        color: "#526fd7",
                      }}
                    >
                      {topic}
                    </p>
                    <div className="d-flex align-items-center mt-2">
                      <li>
                        <Calendar className="me-2" />{" "}
                        {convertDate(recording_start)}
                      </li>
                      <li>
                        <Timer className="me-2" />
                        {getTimeDifference(recording_start, recording_end)}
                      </li>
                    </div>
                  </div>
                </div>
              </NavLink>
              <Dropdown>
                <Dropdown.Toggle className="moreOptions" id="dropdown-basic">
                  <MoreHorizontal className="moreIcon" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    // href="#/action-2"
                    onClick={() => goToDetailsPage(_id)}
                  >
                    View
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    className="actionNav me-4"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal1"
                    onClick={() => setworkData(object)}
                  >
                    Remove
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

            </div>

            <NavLink
              to={`/call_recordings/transcript?meetingId=${_id}`}
              style={{ display: "none" }}
            >
              <div className="col folderSection mb-4">
                <div className="comon-notes">
                  <div className="d-flex justify-content-between align-items-center">
                    <figure className="m-0">
                      <img src="images/folder-icon.png" alt="folder" />
                    </figure>
                    <div className="auto-sec ms-2">
                      <p
                        className="m-0"
                        style={{ fontSize: "12px", fontWeight: 500 }}
                      >
                        {" "}
                        {/* Create By{" "}
                        <span className="createdByName">
                          edededed
                        </span> */}
                      </p>
                    </div>
                  </div>
                  <h6 className="note-titel mt-4">{topic}</h6>
                  <p className="paragraph">
                    {" "}
                    <p
                      className="text-wrap-afte-three-line"
                    //   dangerouslySetInnerHTML={{ __html: description }}
                    >
                      {transcript}{" "}
                    </p>
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="mt-3 mb-0">
                      {convertDate(recording_start)}
                    </h6>
                    <OverlayTrigger
                      trigger="hover"
                      //   key={i}
                      placement={"top"}
                      overlay={
                        <Popover id={`popover-positioned`}>
                          <Popover.Body className="p-2">
                            {/* <h6 className="mb-0" style={{ fontSize: "12px" }}>
                              Public link enabled
                            </h6> */}
                            <div>
                              <small>
                                {" "}
                                Shared with {permittedUsers?.length} people
                              </small>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      {/* {permittedUsers?.length > 0 ? ( */}
                      <img
                        style={{ position: "relative", top: "11px" }}
                        src={userColored}
                      />
                      {/* ) : (
                        <></>
                      )} */}
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </NavLink>
          </>
        );
      });
    }
  };

  return (
    <>
      <div className="d-flex top-ps-seection justify-content-between align-items-center">
        <h2 className="mb-0 search-btn-back">Recordings & Transcripts</h2>
      </div>
      <div className="notes-d-section mt-4">{getFolderTable()}</div>

      <div
        className="modal fade addmodal cm-modal-all"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ border: "none", marginTop: "10px" }}
            >
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button
                id="closeContentModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                {/* <div className="modalWrapper">
                  <div className="linkWrapper">
                    <div className="linkChild">
                      <span className="linkTitle">Shareable link</span>
                    </div>
                    <div className="copyLink">
                      <CopyToClipboard copyText="https://www.evernote.com/shard/s620/sh/53364b71-095d-5d96-eae4-024eb45186cc/190416813732a37ee359c6cf6d40ba90" />
                    </div>
                  </div>
                  <div role="link" tabIndex="0">
                    <input
                      className="shareableLink"
                      type="text"
                      readOnly
                      value="https://www.evernote.com/shard/s620/sh/53364b71-095d-5d96-eae4-024eb45186cc/190416813732a37ee359c6cf6d40ba90"
                    />
                  </div>
                  <div style={{ marginTop: "5px", fontSize: "14px" }}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="me-2"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.07 11.373a3.483 3.483 0 11.002-6.967 3.483 3.483 0 01-.001 6.967zm0-8.707c-3.243 0-5.962 2.221-6.736 5.224.774 3.003 3.493 5.224 6.737 5.224s5.962-2.221 6.736-5.224c-.774-3.003-3.492-5.224-6.736-5.224zM9.813 7.89a1.741 1.741 0 11-3.482 0 1.741 1.741 0 013.482 0z"
                        fill="#00A82D"
                      ></path>
                    </svg>
                    Anyone with the link can <strong>view</strong>
                  </div>
                </div> */}

                <div className="mt-4 inviteSection text-left">
                  <div className="inviteTitle text-start">Invite someone</div>
                  <InputGroup className="mb-3 inputGroupWrppr">
                    <FormControl
                      placeholder="Enter email"
                      aria-label="Enter email"
                      aria-describedby="basic-addon2"
                      className="inputGroupInput"
                      onChange={(event) => handleChange(event)}
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      className="inputGroups"
                      style={{ backgroud: "#fff" }}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          className="dropdownBtn"
                          id="dropdown-basic"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            color: "#000",
                          }}
                        >
                          Can edit and invite
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#/action-1"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "#000",
                            }}
                          >
                            Can edit and invite
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Can edit
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Can view
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </InputGroup.Text>
                  </InputGroup>
                </div>

                {show ? (
                  <div
                    className="d-flex justify-content-end"
                    style={{ border: "none" }}
                  >
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ marginRight: "1rem" }}
                    //   onClick={() => {
                    //     setEmail("");
                    //   }}
                    >
                      Cancel
                    </button>
                    {workData.permittedUsers.filter(
                      (o) => o.email === userEmail.trim()
                    ).length > 0 ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ backgroundColor: "#5746ec", border: "none" }}
                        disabled
                      // onClick={() =>makeUpdate()}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ backgroundColor: "#5746ec", border: "none" }}
                        // onClick={() =>updatePermission(
                        //   payload.permission,
                        //   payload.email
                        // )}
                        onClick={makeUpdate}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                ) : (
                  ""
                )}

                <div>
                  <div className="sharedInfoWrapper">
                    <div className="sharedInfoChild">
                      <div className="sharedInfoProfile">
                        <div
                          className="shareWrppr text-start"
                          id="qa-USER_LIST_ROW_0"
                        >
                          <div className="emailSection">
                            <div className="profileIconWrppr text-left">
                              <div
                                className="profileIcon"
                                style={{
                                  backgroundImage:
                                    "url(https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=Dharmendra+Yadav)",
                                }}
                              ></div>
                              <div className="ms-3">
                                <div
                                  id="qa-USER_LIST_USER_NAME"
                                  className="nameInfo"
                                >
                                  <span className="">
                                    Dharmendra Yadav (you)
                                  </span>
                                </div>
                                <div
                                  className="emailInfo"
                                  id="qa-USER_LIST_EMAIL"
                                >
                                  <span className="">
                                    dharmendra.yadav@techrefic.com
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="Yourr_9w71MjtL3S9I4G">
                            <div className="">
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="dropdownBtn"
                                  id="dropdown-basic"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "#000",
                                  }}
                                >
                                  Can edit and invite
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item href="#/action-1">
                                    Can edit and invite
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-2">
                                    Can edit
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">
                                    Can view
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <div
                          className="shareWrppr text-start"
                          id="qa-USER_LIST_ROW_0"
                        >
                          <div className="emailSection">
                            <div className="profileIconWrppr">
                              <div
                                className="profileIcon"
                                style={{
                                  backgroundImage:
                                    "url(https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=Manas+Kumar)",
                                }}
                              ></div>
                              <div className="ms-3">
                                <div
                                  id="qa-USER_LIST_USER_NAME"
                                  className="nameInfo"
                                >
                                  <span className="">Manas Kumar</span>
                                </div>
                                <div
                                  className="emailInfo"
                                  id="qa-USER_LIST_EMAIL"
                                >
                                  <span className="">
                                    manas.kumar@techrefic.in
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="Yourr_9w71MjtL3S9I4G">
                            <div className="">
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="dropdownBtn"
                                  id="dropdown-basic"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "#000",
                                  }}
                                >
                                  Can edit and invite
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item href="#/action-1">
                                    Can edit and invite
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-2">
                                    Can edit
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">
                                    Can view
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="sharedInfoWrapper">
                    <div className="sharedInfoChild">
                      <div className="sharedInfoProfile">{getPUserList()}</div>
                    </div>
                  </div>
                </div>

                <div className="ZP9BDnxHhGA9rSLoFsa3">
                  <button
                    id="qa-SHARE_DIALOG_STOP"
                    className="cDCnsp1vcViX1pVJM7MV"
                    type="button"
                    tabIndex="0"
                    onClick={stopSharing}
                  >
                    Stop sharing
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete modal */}
      <div
        className="modal fade"
        id="DeleteModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ border: "none", marginTop: "-10px" }}
            >
              <h4 className="modal-title">Are you sure ?</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="">
                <div className="text-left">
                  <p style={{ marginTop: "12px", marginBottom: "-1rem" }}>
                    Do you really want to deactivate these records? This process
                    cannot be undone.
                  </p>
                </div>
              </div>
            </div>
            {
              recordingList.map((recording) => (
                <div
                  className="modal-footer d-flex justify-content-end"
                  style={{ border: "none" }}
                  key={recording.id}
                >
                  <button
                    data-bs-dismiss="modal"
                    type="button"
                    className="btn btn-secondary"
                    style={{ border: "none" }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => updatePermission(recording?.id)}
                    className="btn btn-primary"
                    style={{ backgroundColor: "#5746ec", border: "none" }}
                  >
                    Delete
                  </button>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
}
export default Transcript;
