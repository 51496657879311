import React from "react";
import { NavLink } from "react-router-dom";
import { FaSistrix } from "react-icons/fa";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import $ from "jquery";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";

function MobileMenu() {
  const userData = JSON.parse(localStorage.getItem("userData"));


  const getAvailableSize = () => {
    // console.log(userData.user.company_id);
    if (userData.user.company_id) {
      const bytes = userData.user.company_id.bucketSize;
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 5368709120) return "0 Byte";
      var i = parseInt(
        Math.floor(Math.log(5368709120 - bytes) / Math.log(1024))
      );
      // console.log(i)
      return (
        Math.round((5368709120 - bytes) / Math.pow(1024, i), 2) + " " + sizes[i]
      );
    } else {
      return 0;
    }
  };

  const getPercentageUse = () => {
    if (userData.user.company_id) {
      const bytes = userData.user.company_id.bucketSize;
      // console.log( (bytes/5368709120))
      return bytes / 5368709120;
    } else {
      return 0;
    }
  };

  return (
    <>
      <div
        className="offcanvas offcanvas-start mobile-canvas"
        tabIndex="-1"
        id="offcanvasmenu"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            Offcanvas
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="inside0div">
            <div className="logo-admin mb-3">
              <NavLink to="/">
                <img src="images/logo-main.svg" alt="logo" />
              </NavLink>
            </div>

            <div className="main-menu">
              <ul className="list-unstyled mainMenuUL">
                <li>
                  <h5> Dashboard </h5>
                  {userData.user.role_id &&
                    userData.user.role_id.findExperts?.view === true ? (
                    <NavLink to="/find-expert" activeclassname="menu_active">
                      <span className="menu-icon-new">
                        <img src="/images/search-icon.svg" alt="sr" />
                      </span>
                      <span className="menu-text"> Find Experts </span>{" "}
                    </NavLink>
                  ) : null}

                  {userData.user.role_id &&
                    userData.user.role_id.projects?.view === true ? (
                    <NavLink to="/projects" activeclassname="menu_active">
                      <span className="menu-icon-new">
                        <img src="/images/side-icon2.svg" alt="sr" />
                      </span>
                      <span className="menu-text"> Projects </span>{" "}
                    </NavLink>
                  ) : null}
                </li>

                <li>
                  <h5> FOR YOU </h5>
                  {userData.user.role_id &&
                    userData.user.role_id.collaborate?.view === true ? (
                    <NavLink to="/call_recordings" activeclassname="menu_active">
                      <span className="menu-icon-new">
                        <img src="/images/side-icon3.svg" alt="sr" />
                      </span>
                      <span className="menu-text"> Collaborate </span>{" "}
                    </NavLink>
                  ) : null}

                  {/* {userData.user.role_id &&
                  userData.user.role_id.conversation?.view === true ? (
                    <NavLink to="/share" activeclassname="menu_active">
                      <span className="menu-icon-new">
                        <PeopleOutlineIcon />
                      </span>
                      <span className="menu-text"> Shared with me</span>{" "}
                    </NavLink>
                  ) : null} */}

                  {/* {userData.user.role_id &&
                  userData.user.role_id.conversation.view === true ? (
                    <NavLink to="/conversations" activeclassname="menu_active">
                      <span className="menu-icon-new">
                        <img src="/images/side-icon4.svg" alt="sr" />
                      </span>
                      <span className="menu-text"> Conversations</span>{" "}
                    </NavLink>
                  ) : null} */}

                  {userData.user.role_id &&
                    userData.user.role_id.expertsCall?.view === true ? (
                    <NavLink to="/expert-call" activeclassname="menu_active">
                      {" "}
                      <span className="menu-icon-new">
                        <img src="/images/icn.png" alt="sr" />
                      </span>{" "}
                      <span className="menu-text"> Expert Calls </span>{" "}
                    </NavLink>
                  ) : null}

                  {/* {userData.user.role_id &&
                  userData.user.role_id.news.view === true ? (
                    <NavLink
                      to="/news"
                      activeclassname="menu_active"
                      style={{ display: "none" }}
                    >
                      {" "}
                      <span className="menu-icon-new">
                        <img src="/images/search-icon6.svg" alt="sr" />
                      </span>{" "}
                      <span className="menu-text"> News </span>{" "}
                    </NavLink>
                  ) : null} */}
                </li>

                <li>
                  <h5> GENERAL </h5>
                  {userData.user.role_id &&
                    userData.user.role_id.userManagement?.view === true ? (
                    <NavLink
                      to="/helpsupport"
                      activeclassname="menu_active"
                      className="menu-text"
                      data-bs-toggle="collapse"
                      data-bs-target="#home-collapse"
                      aria-expanded="false"
                    >
                      {" "}
                      <span className="menu-icon-new">
                        <img src="/images/search-icon7.svg" alt="sr" />
                      </span>
                      <span className="menu-text"> User Management </span>{" "}
                    </NavLink>
                  ) : null}

                  {userData.user.role_id &&
                    userData.user.role_id.userManagement?.view === true ? (
                    <div className="collapse" id="home-collapse">
                      <ul className="btn-toggle-nav list-unstyled fw-normal">
                        <li>
                          <NavLink
                            to="/usermangement/roles"
                            className="link-dark d-inline-flex text-decoration-none rounded"
                            style={{ marginTop: "-16px", paddingLeft: "47px" }}
                          >
                            Roles
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/usermangement/users"
                            className="link-dark d-inline-flex text-decoration-none rounded"
                            style={{ marginTop: "-16px", paddingLeft: "47px" }}
                          >
                            User
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  ) : null}

                  <NavLink to="/help-support" activeclassname="menu_active">
                    {" "}
                    <span className="menu-icon-new">
                      <img src="/images/search-icon8.svg" alt="sr" />
                    </span>
                    <span className="menu-text"> Help & Support </span>{" "}
                  </NavLink>

                  <a
                    href="https://nextyn.com/privacy-policy"
                    target="_blank"
                    activeclassname="menu_active"
                  >
                    {" "}
                    <span className="menu-icon-new">
                      <img src="/images/search-icon9.svg" alt="sr" />
                    </span>{" "}
                    <span className="menu-text"> Privacy </span>{" "}
                  </a>

                  <NavLink
                    to="/storage"
                    style={{
                      pointerEvents: "none",
                      borderTop: "1px solid #ededf0",
                    }}
                    activeclassname="menu_active"
                  >
                    {" "}
                    <span className="menu-icon-new">
                      <CloudQueueIcon />
                    </span>
                    <span className="menu-text"> Storage </span>{" "}
                  </NavLink>
                  <div className="progress" style={{ height: "0px" }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${getPercentageUse()}%` }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>

                  <p>{getAvailableSize()} of 5 GB used</p>
                </li>
              </ul>
            </div>

            {/* <div className="main-menu">
              <ul className="list-unstyled">
                <li data-bs-dismiss="offcanvas" aria-label="Close">
                  <h5> Dashboard </h5>
                  <NavLink to="/find-expert" activeclassname="menu_active">
                    <span className="menu-icon-new">
                      <img src="images/search-icon.svg" alt="sr" />
                    </span>{" "}
                    <span className="menu-text"> Find Experts </span>{" "}
                  </NavLink>
                  <NavLink to="/projects" activeclassname="menu_active">
                    {" "}
                    <span className="menu-icon-new">
                      <img src="images/side-icon2.svg" alt="sr" />
                    </span>{" "}
                    <span className="menu-text"> Projects </span>{" "}
                  </NavLink>
                </li>

                <li data-bs-dismiss="offcanvas" aria-label="Close">
                  <h5> FOR YOU </h5>
                  <NavLink to="/collaborate" activeclassname="menu_active">
                    <span className="menu-icon-new">
                      <img src="images/side-icon3.svg" alt="sr" />
                    </span>{" "}
                    <span className="menu-text"> Collaborate </span>{" "}
                  </NavLink>

                  <NavLink to="/conversations" activeclassname="menu_active">
                    {" "}
                    <span className="menu-icon-new">
                      <img src="images/side-icon4.svg" alt="sr" />
                    </span>{" "}
                    <span className="menu-text"> Conversations</span>{" "}
                  </NavLink>

                  <NavLink to="/expertcell" activeclassname="menu_active">
                    {" "}
                    <span className="menu-icon-new">
                      <img src="images/icn.png" alt="sr" />
                    </span>{" "}
                    <span className="menu-text"> Expert Calls </span>{" "}
                  </NavLink>

                  <NavLink to="/news" activeclassname="menu_active">
                    {" "}
                    <span className="menu-icon-new">
                      <img src="images/search-icon6.svg" alt="sr" />
                    </span>{" "}
                    <span className="menu-text"> News </span>{" "}
                  </NavLink>
                </li>

                <li data-bs-dismiss="offcanvas" aria-label="Close">
                  <h5> GENERAL </h5>
                  <NavLink to="/usermangement" activeclassname="menu_active">
                    <span className="menu-icon-new">
                      <img src="images/search-icon7.svg" alt="sr" />
                    </span>{" "}
                    <span className="menu-text"> User Management </span>{" "}
                  </NavLink>

                  <NavLink to="/helpsupport" activeclassname="menu_active">
                    {" "}
                    <span className="menu-icon-new">
                      <img src="images/search-icon8.svg" alt="sr" />
                    </span>{" "}
                    <span className="menu-text"> Help & Support </span>{" "}
                  </NavLink>

                  <NavLink to="/" activeclassname="menu_active">
                    {" "}
                    <span className="menu-icon-new">
                      <img src="images/search-icon9.svg" alt="sr" />
                    </span>{" "}
                    <span className="menu-text"> Privacy </span>{" "}
                  </NavLink>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
export default MobileMenu;
