import { css } from "@emotion/css";
// import { Editor } from "@tinymce/tinymce-react";
import { Modal, Spinner } from "react-bootstrap";
import { Markup } from "interweave";
import React, { useRef, useState, useEffect } from "react";
import AvatarGroup from "react-avatar-group";
import { BsPlusLg } from "react-icons/bs";
import { NavLink, useLocation, createSearchParams } from "react-router-dom";
import ScrollToBottom from "react-scroll-to-bottom";
import "../App.css";
// import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";
import { BsChevronLeft, SendIcon } from "../Utils/Icons";
import { Button, message } from "antd";
import BlankMessages from "../assests/images/blankMessage.svg";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import API_PATH from "../Constants/api-path";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  Link,
  Heading,
  List,
  Alignment,
  Indent,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import { MdOutlineClose } from "react-icons/md";
import moment from "moment";

const ROOT_CSS = css({
  height: 330,
});

let seenByArray = [];

function ProjectsDetailsMessage() {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  let project_id = query.get("project_id");

  function randomColor() {
    var color = [];
    for (var i = 0; i < 15; i++) {
      let hex = Math.floor(Math.random() * 0xffffff);
      let color1 = "#" + hex.toString(16);
      color.push(color1);
    }

    return color;
  }

  const userData = JSON.parse(localStorage.getItem("userData"));
  const [deleteBttonLoader, setDeleteBttonLoader] = useState(false);
  const [projectDetails, setProjectDetails] = useState();
  const [value, setValue] = useState("");
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [online, setOnline] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tagNames, setTagNames] = useState([]);
  const [chat, setChat] = useState([]);
  const messagesEndRef = useRef(null);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [chatId, setChatId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [expertsType, setExpertsType] = useState([]);

  const handelMessage = async () => {
    const trimmedValue = value?.trim();
    const currentTime = moment().toISOString();
    let tempObj = {
      "_id": "66b5ace1a520d4d1a3fctemp",
      "message": [
        {
          "_id": "66b5ace1a520d4d1a3fctemp",
          "message": trimmedValue,
          "sent_by": "client",
          "project_id": project_id,
          "admin_id": "",
          "user_id": userData?.user?._id,
          "seen": 1,
          "email": "no",
          "status": 1,
          "created_at": currentTime,
          "updated_at": currentTime
        }
      ],
      "sent_by": "client",
      "project_id": project_id,
      "admin_id": "",
      "user_id": userData?.user?._id,
      "seen": 1,
      "email": "no",
      "status": 1,
      "created_at": currentTime,
      "updated_at": currentTime,
      "adminData": [],
      "clientData": [
        {
          ...userData?.user
        }
      ],
      "projectData": [
        {
          ...projectDetails
        }
      ]
    }
    setChat([{ ...tempObj }, ...chat])
    if (trimmedValue?.length > 0) {
      if (isEdit) {
        EditChat();
      } else {
        try {
          const res = await fetch(`${API_PATH.CHAT_INSERT}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
            body: JSON.stringify({
              admin_id: userData?.user?._id,
              sent_by: "client",
              message: value,
              project_id: project_id,
              user_id: userData?.user?._id,
            }),
          });
          setValue("");
          const result = await res.json();
          if (result) {
            getMessage(1, "NEXT");

            // let clientNewMessage = {
            //   clientData: [
            //     {
            //       _id: userData?.user?._id,
            //       email: userData?.user?.email,
            //       name: userData?.user?.name,
            //     },
            //   ],
            //   message: value,
            //   status: "1",
            //   seen: 1,
            //   sent_by: "client",
            //   created_at: new Date(),
            //   adminData: [],
            // };
            // let arrayUniqueByKey = [...chat, clientNewMessage];
            // arrayUniqueByKey.sort(
            //   (d1, d2) =>
            //     new Date(d2.created_at).getTime() -
            //     new Date(d1.created_at).getTime()
            // );
            // setChat(arrayUniqueByKey);
          }
        } catch (error) {
          return message.error("Something went wrong server error");
        }
      }
    }
  };

  const getMessage = (pageNumber, scrollType) => {
    axios
      .get(
        `${API_PATH.CLIENT_ADMIN_CHAT_DATA}/${project_id}/?page=${pageNumber}&limit=100`
      )
      .then((res) => {
        setLoading(true);
        if (chat.length > 0 && scrollType === "NEXT") {
          let chatArray = res.data.data;
          let newChatArray = chatArray.filter((o) => o.seen === "0");
          if (newChatArray.length > 0) setChat([...chat, ...newChatArray]);
        } else if (chat.length > 0 && scrollType === "PREV") {
          let chatArray = res.data.data;
          let mergedArray = [...chatArray, ...chat];
          const arrayUniqueByKey = [
            ...new Map(
              mergedArray.map((item) => [item["created_at"], item])
            ).values(),
          ];
          arrayUniqueByKey.sort(
            (d1, d2) =>
              new Date(d2.created_at).getTime() -
              new Date(d1.created_at).getTime()
          );
          if (chatArray.length > 0) setChat(arrayUniqueByKey);
        } else {
          setChat([...res.data.data]);
        }
        setTagNames([res?.data?.expert_suggestion]);
        setOnline([res?.data?.client_team]);
        // scrollToBottom();
        // startInterVal();
      })
      .catch((error) => {
        console.error(error);
        // return message.error("something went wrong server error");
      });
  };

  const getProjectDetails = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.token}`,
    };

    axios
      .get(
        `${API_PATH.PRJECT_DETAILS}/${project_id}`,

        { headers }
      )
      .then((res) => {
        setProjectDetails(res?.data?.data);
      })
      .catch((err) => {
        console.error(err.messagex);
      });
  };

  useEffect(() => {
    getProjectDetails();
    getProjectExpertList();
    chatSeen();
  }, []);

  // Invoke chat seen API when we enter into chat component
  const chatSeen = () => {
    let payload = {
      client_id: userData?.user?._id,
      project_id: project_id,
    };
    axios
      .post(`${API_PATH.CHAT_SEEN}`, payload)
      .then((res) => { })
      .catch((error) => {
        console.error(error);
        // return message.error("something went wrong server error");
      });
  };

  // Invoke ChatSeenUserData API when we enter into chat component

  // const ChatSeenUserData = (chat_id) => {
  //   let payload = {
  //     chat_id: chat_id,
  //   };
  //   let res = axios
  //     .post(`${API_PATH.CHAT_SEEN_USER_DATA}`, payload)
  //     .then((res) => {
  //       let seenBY = res?.data?.data;
  //       seenByArray = [];
  //       // let seenByArr = [];
  //       seenBY.map((data) => {
  //         if (data?.clientData && data?.clientData.length > 0) {
  //           seenByArray.push(data?.clientData?.[0]?.name);
  //         }
  //       });
  //       // return seenByArray;
  //       // setAdminSeen(seenByArray);
  //     })
  //     .catch((error) => {
  //       // return message.error("something went wrong server error");
  //     });
  // };

  //Get expert list details by project id
  const getProjectExpertList = () => {
    axios
      .get(`${API_PATH.PROJECT_EXPERT_LIST}/${project_id}`)
      .then((res) => {
        let experts = [];
        res?.data?.data?.forEach((item, index) => {
          const experience = item?.admin_expert_experience;
          if (experience && experience.length > 0) {
            const currentExp = experience[0];
            let mentionString = `@${currentExp?.current_designation ||
              currentExp?.previous_designation
              } at ${currentExp?.current_compny || currentExp?.previous_compny}`;

            if (experience.length > 1) {
              const previousExp = experience[1];
              mentionString += ` (Previously: ${previousExp?.previous_designation} at ${previousExp?.previous_compny})`;
            }

            let temp = {
              id: mentionString,
              userId: `${index + 1}`,
              name: mentionString,
              link: `/project/expert-profile?project_id=${project_id}&expertId=${item?._id}`,
            };
            experts.push(temp);
          }
        });
        setExpertsType(experts);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getMessage(1, "NEXT");
    // seenMessage();
    scrollToBottom();
    const interval = setInterval(() => {
      getMessage(1, "NEXT");
    }, 7000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScroll = (e) => {
    let smessages = document.getElementById("messagesEndRef");
    if (smessages?.scrollTop === 0) {
      setCurrentPageNumber(currentPageNumber + 1);
      getMessage(currentPageNumber, "PREV");
    }
  };

  function AvatarDetails(arr) {
    var arr1 = [];
    for (var x in arr) {
      arr1.push(arr[x]?.name || "");
    }
    return arr1;
  }

  function formatTimeAgo(numDays) {
    if (numDays <= 30) {
      return `${numDays} days ago`;
    } else {
      const numMonths = Math.floor(numDays / 30);
      return `${numMonths} month${numMonths > 1 ? "s" : ""} ago`;
    }
  }

  // remember this is equivalent to 06 01 2010
  //dates in js are counted from 0, so 05 is june

  function Calculate_days_hours(date1, date2) {
    const difference = Math.abs(date1 - date2);
    const minutes = Math.floor(difference / 1000 / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    }
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "hidden";
    };
  }, []);

  function MentionLinks(Editor) {
    Editor.conversion.for("upcast").elementToAttribute({
      view: {
        name: "a",
        key: "data-mention",
        classes: "mention",
        attributes: {
          target: "_blank",
          href: true,
        },
      },
      model: {
        key: "mention",
        value: (viewItem) =>
          Editor.plugins.get("Mention").toMentionAttribute(viewItem),
      },
      converterPriority: "high",
    });

    // Downcast the model "mention" text attribute to a view
    //
    //    <a href="..." class="mention" data-mention="...">...</a>
    //
    // element.
    Editor.conversion.for("downcast").attributeToElement({
      model: "mention",
      view: (modelAttributeValue, { writer }) => {
        if (!modelAttributeValue) {
          return;
        }

        let href;
        href = `${modelAttributeValue.link}`;
        var mentionF =
          modelAttributeValue.id + " " + modelAttributeValue.avatar;
        return writer.createAttributeElement(
          "a",
          {
            class: "mention",
            "data-mention": mentionF,
            href,
            target: "_blank",
          },
          {
            // Make mention attribute to be wrapped by other attribute elements.
            priority: 20,
            // Prevent merging mentions together.
            id: modelAttributeValue?.uid,
          }
        );
      },
      converterPriority: "high",
    });
  }

  // async function getNewMessage() {
  //   try {
  //     const res = await fetch(`${API_PATH.CHAT_NEW_MESSAGE}/${project_id}`, {
  //       method: "POST",
  //       body: JSON.stringify({
  //         client_id: userData.user.email,
  //       }),
  //     });
  //     const result = await res.json();
  //     if (result) {
  //       const messages = document.getElementById("messagesEndRef");
  //       if (messages) {
  //         var shouldScroll =
  //           messages.scrollTop + messages.clientHeight ===
  //           messages.scrollHeight;
  //         if (!shouldScroll) {
  //           scrollToBottom(shouldScroll);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     return toast.error("something went wrong server error", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 3000,
  //     });
  //   }
  // }

  const getSeenAdmins = (adminArr) => {
    if (Array.isArray(adminArr)) {
      return adminArr.map(function (ele, i) {
        return ele.admin_name;
      });
    }
  };

  const getSeenClients = (clientArr) => {
    if (Array.isArray(clientArr)) {
      return clientArr.map(function (ele, i) {
        return ele.client_name;
      });
    }
  };

  // const seenMessage = async () => {
  //   const res = await fetch(`${API_PATH.CHAT_SEEN}/${project_id}`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       email: userData.user.email,
  //     }),
  //   });
  //   const result = await res.json();
  //   const res1 = await fetch(`${API_PATH.CHAT_CLIENT_SEEN}/${project_id}`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       email: userData.user.email,
  //     }),
  //   });
  //   const result1 = await res1.json();
  // };

  // function scrollToBottom() {
  //   const messages = document.getElementById("messagesEndRef");
  //   if (messages) messages.scrollTop = messages.scrollHeight;
  // }

  const handleEditorChange = (content) => {
    setValue(content);
  };

  const DeleteChat = async () => {
    // e.preventDefault();
    // setLoading(true);
    setDeleteBttonLoader(true);
    try {
      const res = await fetch(`${API_PATH.CHAT_DELETE}/${chatId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        getMessage(1, "NEXT");
        setDeleteBttonLoader(false);
        if (res.status === 200 || res.status === true) {
          message.success("Message Successfully Deleted!!");
          setDeleteModal(false);
        }
      } else {
        message.error("something went wrong server error");
      }
    } catch (error) {
      // setLoading(false);

      message.error("something went wrong server error");
    }
    // setLoading(false);
  };

  //editing the chat

  const EditChat = async () => {
    try {
      const res = await fetch(`${API_PATH.CHAT_EDIIT}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          message: value,
          chat_id: chatId,
        }),
      });

      const result = await res.json();
      if (result) {
        getMessage(1);
        if (res.status === 200 || res.status === true) {
          setIsEdit(false);
          setValue("");
          message.success("Message Successfully Updated!!");
        }
      } else {
        message.error("something went wrong server error");
        setIsEdit(false);
      }
    } catch (error) {
      setIsEdit(false);
      message.error("something went wrong server error");
    }
  };

  const getChatId = (_id) => {
    setChatId(_id);
  };

  const involkePlugin = (editor) => {
    return editor.execute("mention");
  };

  const editorRef = useRef(null);
  const [iSchanged, setIschanged] = useState(false);

  useEffect(() => {
    if (editorRef.current) {
      // editorRef.current.editor.execute("mention"); // Invoke the mention plugin
    }
  }, [iSchanged]);

  document.title = "Client | Projects Details";
  return (
    <>
      {/* <MobileMenu /> */}
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start">
                <TopMenu2 />
              </div>
            </div> */}

            {loading ? (
              <>
                <div className=" w-100">
                  <div className="body-expart-div messageScreen request-page projects-scope">
                    <div className="d-md-flex top-ps-seection justify-content-between align-items-center">
                      <h4 className="d-flex align-items-center">
                        <NavLink
                          to={{
                            pathname: "/projects-details",
                            search: `?${createSearchParams({ project_id })}`,
                          }}
                          style={{ textAlign: "left" }}
                        >
                          <BsChevronLeft className="me-3 search-btn-back" />
                        </NavLink>
                        Messages
                      </h4>
                      <div className="d-flex justify-content-center align-items-center">
                        <ul
                          className="nav nav-pills mb-3 mt-4"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <NavLink
                              to={{
                                pathname: "/projects-details",
                                search: `?${createSearchParams({
                                  project_id,
                                })}`,
                              }}
                              className="nav-link"
                            >
                              Overview
                            </NavLink>
                          </li>
                          {userData?.user?.role_id?.projects?.message ===
                            true ? (
                            <>
                              <li className="nav-item" role="presentation">
                                <NavLink
                                  to={{
                                    pathname: "/projects-details-message",
                                    search: `?${createSearchParams({
                                      project_id,
                                    })}`,
                                  }}
                                  className="nav-link"
                                >
                                  Messages
                                </NavLink>
                              </li>
                            </>
                          ) : null}

                          {userData?.user?.role_id?.projects?.expertList ===
                            true ? (
                            <>
                              <li className="nav-item" role="presentation">
                                <NavLink
                                  to={{
                                    pathname: "/projects-details-expert",
                                    search: `?${createSearchParams({
                                      project_id,
                                    })}`,
                                  }}
                                  className="nav-link"
                                >
                                  Experts
                                </NavLink>
                              </li>
                            </>
                          ) : null}
                        </ul>
                      </div>

                      <button
                        className="btn add-ps-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#carteProjectModal"
                        style={{ visibility: "hidden" }}
                      >
                        {" "}
                        <BsPlusLg /> Create Project{" "}
                      </button>
                    </div>

                    <span className="sp-hr w-100" />

                    <div
                      className="projects-details-page details-message-part"
                      style={{ marginBottom: "20px" }}
                    >
                      <div
                        className="members-list d-flex align-items-center"
                        style={{ justifyContent: "space-between" }}
                      >
                        <span
                          className="ms-3"
                          style={{
                            fontWeight: 500,
                            fontSize: "18px",
                            color: "#5746ec",
                          }}
                        >
                          {projectDetails?.project_title}
                        </span>
                        <div className="menmber-icons avatarImg d-flex d-none">
                          <AvatarGroup
                            avatars={AvatarDetails(online)}
                            // avatars={["Demo Account" /* or IAvatar objects */]}
                            initialCharacters={1}
                            max={2}
                            size={30}
                            displayAllOnHover
                            shadow={2}
                            fontColor="#f33636"
                            backgroundColor="#EFEFEF"
                            fontSize=".38"
                          />
                          <span className="ms-3">
                            {" "}
                            {online.length} Members Joined{" "}
                          </span>
                        </div>{" "}
                      </div>

                      <div className="message-text-area12 position-relative">
                        {chat?.length > 0 ? (
                          <>
                            <ul
                              className="mainComment"
                              id="messagesEndRef"
                              onScroll={handleScroll}
                            >
                              <ScrollToBottom className={ROOT_CSS}>
                                {chat?.sort((a, b) => moment(a?.created_at)?.diff(moment(b?.created_at)))?.map((curElem) => {
                                  if (curElem?.sent_by === "admin") {
                                    return (
                                      <>
                                        <li className="adminMessage clearfix">
                                          <div className="sdee">
                                            <div className="msgContent">
                                              <div className="avatarImg">
                                                <img
                                                  src={
                                                    "https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=" +
                                                    curElem?.adminData[0]?.name
                                                  }
                                                  alt={
                                                    curElem?.adminData[0]?.name
                                                  }
                                                />
                                              </div>
                                              <Markup
                                                className="markup-para"
                                                style={{ width: "80%" }}
                                                content={
                                                  curElem?.message[0]?.message
                                                }
                                              />
                                            </div>
                                            <small>
                                              {Calculate_days_hours(
                                                new Date(
                                                  curElem?.message[0]?.created_at
                                                ),
                                                new Date()
                                              )}
                                            </small>
                                          </div>
                                        </li>
                                      </>
                                    );
                                  } else if (curElem?.sent_by === "client") {
                                    return (
                                      <li className="clientMessage clearfix">
                                        <div className="sdee">
                                          <div className="msgContent">
                                            <div className="SentByClient">
                                              <Markup
                                                className="markup-para"
                                                content={
                                                  curElem?.message[0]?.message
                                                }
                                              />
                                              <>
                                                <div className="d-flex justify-content-between">
                                                  {seenByArray.length > 0 && (
                                                    <>
                                                      <div className="d-flex align-items-center mt-3">
                                                        <strong
                                                          className="me-2"
                                                          style={{
                                                            color: "#5c5f62",
                                                          }}
                                                        >
                                                          Seen by
                                                        </strong>
                                                        <span>
                                                          <AvatarGroup
                                                            avatars={seenByArray?.map(
                                                              (item) => item
                                                            )}
                                                            initialCharacters={
                                                              2
                                                            }
                                                            fontColor="#f33636"
                                                            max={2}
                                                            size={30}
                                                            fontSize=".38"
                                                            displayAllOnHover
                                                            uppercase={true}
                                                            backgroundColor="#EFEFEF"
                                                          />
                                                        </span>
                                                      </div>
                                                    </>
                                                  )}

                                                  <div>
                                                    {curElem?.clientData[0]
                                                      ?._id ===
                                                      userData?.user?._id && (
                                                        <>
                                                          <EditOutlined
                                                            // onClick={() => goToDetailsPage(_id)}
                                                            style={{
                                                              fontSize: "13px",
                                                            }}
                                                            className="me-3"
                                                            onClick={() => {
                                                              setIsEdit(true);
                                                              setValue(
                                                                curElem
                                                                  ?.message?.[0]
                                                                  ?.message
                                                              );
                                                              getChatId(
                                                                curElem?._id
                                                              );
                                                            }}
                                                          ></EditOutlined>
                                                          <DeleteOutlined
                                                            onClick={() => {
                                                              setDeleteModal(
                                                                true
                                                              );
                                                              getChatId(
                                                                curElem?._id
                                                              );
                                                            }}
                                                            style={{
                                                              fontSize: "13px",
                                                            }}
                                                          />
                                                        </>
                                                      )}
                                                  </div>
                                                </div>
                                              </>
                                            </div>
                                            <div className="avatarImg">
                                              <img
                                                className="senderName"
                                                src={
                                                  "https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=" +
                                                  curElem?.clientData[0]?.name
                                                }
                                                alt={
                                                  curElem?.clientData[0]?.name
                                                }
                                              />
                                            </div>
                                          </div>
                                          <small>
                                            {Calculate_days_hours(
                                              new Date(
                                                curElem?.message[0]?.created_at
                                              ),
                                              new Date()
                                            )}
                                          </small>
                                        </div>
                                      </li>
                                    );
                                  }
                                })}
                              </ScrollToBottom>
                            </ul>
                          </>
                        ) : (
                          <div className="text-center noResultFound">
                            <div className="container">
                              <div
                                className="row d-flex justify-content-center align-items-center"
                                style={{ height: "calc(100vh - 270px)" }}
                              >
                                <div className="col-md-6">
                                  <img src={BlankMessages} alt="srv"></img>
                                </div>
                                <div className="col-md-6">
                                  <h3
                                    style={{
                                      fontSize: "18px",
                                      textAlign: "left",
                                      fontWeight: "300",
                                      lineHeight: "26px",
                                    }}
                                  >
                                    Use this space to start a conversation with
                                    Nextyn's team driving your project
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div ref={messagesEndRef} />
                        <div className="messageEditorWrapper">
                          <div className="form-group">
                            <div className="main2">
                              <div
                                style={{ height: "100%" }}
                                className="ckeditor_ProjectDetails"
                              >
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={value}
                                  // ref={editorRef}
                                  onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.

                                    editorRef.current = editor;
                                    setIschanged(true);
                                  }}
                                  config={{
                                    extraPlugins: [MentionLinks],
                                    mention: {
                                      feeds: [
                                        {
                                          marker: "@",
                                          feed: expertsType,
                                        },
                                      ],
                                    },
                                    toolbar: [
                                      "bold",
                                      "italic",
                                      "link",
                                      "bulletedList",
                                      "numberedList",
                                    ],
                                    plugins: [
                                      Bold,
                                      Essentials,
                                      Italic,
                                      Paragraph,
                                      Undo,
                                      Link,
                                      Heading,
                                      List,
                                      Alignment,
                                      Mention,
                                      Indent,
                                    ],
                                    placeholder:
                                      "To reference an expert type @",
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setValue(data);
                                  }}
                                  onKeyDown={(event, editor) => {
                                    if (
                                      event?.key === "Enter" &&
                                      !event?.shiftKey
                                    ) {
                                      event?.preventDefault();
                                      handelMessage();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            className="sendBttnMessage"
                            onClick={handelMessage}
                          >
                            <SendIcon />
                          </button>
                          {/* <div
                            className="bi bi-send"
                            size="medium"
                            style={{
                              position: "absolute",
                              right: "77px",
                              bottom: "10px",
                              width: "50px",
                              height: "50px",
                              background: "#5746EC",
                              textAlign: "center",
                              color: "#fff",
                              borderRadius: "50%",
                              lineHeight: "3.70",
                              cursor: "pointer",
                              zIndex: "9999",
                            }}
                            onClick={() => handelMessage()}
                          ></div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Spinner />
            )}
          </div>
        </main>
      </div>

      <Modal
        show={showDeleteModal}
        onHide={() => setDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setDeleteModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Delete Message</h4>
          </div>
          <div>
            This action cannot be undone. Are you sure you want to delete this{" "}
            message ?
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setDeleteModal(false)}
            >
              No
            </button>
            <Button
              onClick={() => {
                DeleteChat();
              }}
              size="large"
              className="antDBttn ms-2"
              loading={deleteBttonLoader}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProjectsDetailsMessage;
