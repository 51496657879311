import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import API_URL from "../../Constants/api-path";
import { history } from "../../Utils/history";
import mydecodedTokenFunction from "../../Utils/DecodedToken";
import { toast } from "react-toastify";
import { notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import crypto from 'crypto-js'
import ENV from "../../Constants/env";
const initialState = {
    user: JSON.parse(localStorage.getItem("userData")),
    error: null,
    loading: false,
    success: false,
}

export const email_login = createAsyncThunk(
    `auth/email_login`,
    async ({ email, password }, { rejectWithValue }) => {
        const response = await fetch(`${API_URL.LOGINURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: crypto.AES.encrypt(password, ENV.CRYPTO_JS_KEY).toString(),
                registrationType: "client"
            })
        });
        let login_response = await response.json()
        if (login_response?.status === 200 || login_response?.status === "200") {
            return login_response
        } else {
            return rejectWithValue(login_response);
        }
    }
)

export const otp_login = createAsyncThunk(
    `auth/otp_login`,
    async ({ email, password }, { rejectWithValue }) => {
        const response = await fetch(`${API_URL.OTPLOGIN}/${email}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                otp: crypto.AES.encrypt(password, ENV.CRYPTO_JS_KEY).toString(),
                registrationType: "client"
            })
        });
        console.log('response: ', response);
        let login_response = await response.json()
        console.log('login_response: ', login_response);
        if (login_response?.status === 200 || login_response?.status === "200") {
            return login_response
        } else {
            console.log("here ?");
            return rejectWithValue(login_response);
        }
    }
)

const slice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem("userData")
            localStorage.removeItem("isAuth")
        },
        resetState: (state) => {
            state.user = null
            state.error = null
            state.loading = false
            state.success = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(email_login?.fulfilled, (state, action) => {
            let result = action.payload
            result.user = JSON.parse(atob(result.user));
            result.access_token = atob(result.access_token);
            result.refresh_token = atob(result.refresh_token);
            localStorage.setItem("userData", JSON.stringify(result));
            const userDecodedData = mydecodedTokenFunction(result.access_token);
            state.user = userDecodedData
            state.loading = false
            state.success = true

            if (result.user.passString) {
                history.navigate("/reset-password");
            } else {
                localStorage.setItem("isAuth", true);
                setTimeout(() => {
                    return toast.success("Login Successful!", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }, 100);
                history.navigate("/dashboard");
            }
        })
        builder.addCase(email_login?.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(email_login?.rejected, (state, action) => {
            state.error = action.payload.message
            state.loading = false
            notification.error({
                message: action.payload.message,
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        })
        builder.addCase(otp_login?.fulfilled, (state, action) => {
            console.log('action: ', action);
            let result = action.payload
            result.user = JSON.parse(atob(result.user));
            result.access_token = atob(result.access_token);
            result.refresh_token = atob(result.refresh_token);
            localStorage.setItem("userData", JSON.stringify(result));
            const userDecodedData = mydecodedTokenFunction(result.access_token);
            state.user = userDecodedData
            state.loading = false
            state.success = true

            if (result.user.passString) {
                history.navigate("/reset-password");
            } else {
                localStorage.setItem("isAuth", true);
                setTimeout(() => {
                    return toast.success("Login Successful!", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }, 100);
                history.navigate("/dashboard");
            }
        })
        builder.addCase(otp_login?.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(otp_login?.rejected, (state, action) => {
            console.log('action: ', action);
            state.error = action?.payload?.message
            state.loading = false
            notification.error({
                message: action?.payload?.message,
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        })
    }
})

export const authActions = { ...slice.actions }
export const authReducer = slice.reducer